<template>
  <div
    v-if="
      whitelabelSelectNavbar &&
      whitelabelSelectNavbar.paymentMethods &&
      whitelabelSelectNavbar.paymentMethods.length
    "
  >
    <b-row>
      <b-col md="12" style="display: flex; gap: 10px">
        <export-button
          :filter="exportFilter"
          url="/transaction/report-payment-method/export"
          :disabled="loadindData"
          file-title="Payment_Method_Report"
        />
        <b-button v-b-modal.modal-hierachy v-if="width < 768">
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12">
        <b-card no-body class="p-1">
          <b-card-title class="mb-2">{{
            $t("tabs.reportPaymentsMethods")
          }}</b-card-title>

          <b-row class="mb-2">
            <b-col md="4">
              <b-form-group
                label="Date from"
                label-for="datefrom"
                rules="required"
              >
                <template #label>{{ $t("labels.datefrom") }}</template>
                <flat-pickr
                  v-model="dateFrom"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y - H:i',
                    enableTime: true,
                    dateFormat: 'Y-m-d:H:i',
                    time_24hr: true,
                    wrap: true,
                    plugins: [
                      new yearDropdownPlugin({
                        yearStart: 100,
                        yearEnd: 0,
                      }),
                    ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date To" label-for="dateto" rules="required">
                <template #label>{{ $t("labels.dateto") }}</template>
                <flat-pickr
                  v-model="dateTo"
                  class="form-control"
                  :config="{
                    altInput: true,
                    altFormat: 'd-m-Y - H:i',
                    enableTime: true,
                    dateFormat: 'Y-m-d:H:i',
                    time_24hr: true,
                    wrap: true,
                    plugins: [
                      new yearDropdownPlugin({
                        yearStart: 100,
                        yearEnd: 0,
                      }),
                    ],
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('labels.search_historic')"
                label-for="isHistorical"
              >
                <b-form-checkbox v-model="isHistorical" @change="fetchData()" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('labels.payment_methods')"
                label-for="payment_methods"
              >
                <v-select
                  v-model="selectedPaymentMethod"
                  :options="paymentMethods"
                  :clearable="false"
                  label="name"
                  @input="fetchData()"
                ></v-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('labels.transaction_id')"
                label-for="transactionId"
              >
                <b-form-input
                  v-model="transactionId"
                  @input="lazyFetchData()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('labels.status')" label-for="status">
                <v-select
                  v-model="transactionStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  label="key"
                  @input="fetchData()"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('labels.username')" label-for="username">
                <b-form-input v-model="userName" @input="lazyFetchData()" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-overlay :show="loadindData">
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th>{{ $t("labels.date") }}</b-th>
                  <b-th>{{ $t("labels.transaction_id") }}</b-th>
                  <b-th>{{ $t("labels.userName") }}</b-th>
                  <b-th>{{ $t("deposits") }}</b-th>
                  <b-th>{{ $t("labels.withdrawals") }}</b-th>
                  <b-th>{{ $t("labels.balance") }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  :variant="tr._rowVariant"
                  v-for="(tr, indexTr) in data.principal"
                  :key="indexTr"
                >
                  <b-td class="text-noWrap">{{ tr.createdAt | moment }}</b-td>
                  <b-td class="text-noWrap">{{ tr.transactionID }}</b-td>
                  <b-td class="text-noWrap">{{
                    tr.user_from.userName
                  }}</b-td>
                  <b-td class="text-noWrap">{{
                    tr.credit | currency({ symbol: "" })
                  }}</b-td>
                  <b-td class="text-noWrap">{{
                    tr.debit | currency({ symbol: "" })
                  }}</b-td>
                  <b-td class="text-noWrap">{{
                    tr.netIncome | currency({ symbol: "" })
                  }}</b-td>
                </b-tr>
                <b-tr v-if="data.principal.length < 1">
                  <b-th colspan="6" class="text-center">
                    <div class="m-2">{{ $t("no_data") }}</div>
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <b-table-simple class="mt-2" hover small caption-top responsive>
              <b-thead head-variant="light">
                <b-th colspan="2">{{ $t("total") }}</b-th>
                <b-th></b-th>
                <b-th></b-th>
                <b-th>{{ $t("deposits") }}</b-th>
                <b-th>{{ $t("labels.withdrawals") }}</b-th>
                <b-th>{{ $t("profit") }}</b-th>
              </b-thead>
              <b-tr>
                <b-th></b-th>
                <b-th></b-th>
                <b-th></b-th>
                <b-th></b-th>
                <b-th
                  >{{
                    data.secondLine.creditsAmmount | currency({ symbol: "" })
                  }}
                </b-th>
                <b-th
                  >{{
                    data.secondLine.debitsAmmount | currency({ symbol: "" })
                  }}
                </b-th>
                <b-th
                  >{{
                    data.secondLine.netIncome | currency({ symbol: "" })
                  }}
                </b-th>
              </b-tr>
            </b-table-simple>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <div v-else>No hay metodos de pago en esta whitelabel</div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BAlert,
  BFormCheckbox,
  BCardText,
  BOverlay,
  BCardTitle,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTfoot,
  BTd,
  BTbody,
} from "bootstrap-vue";
import { BTable } from "bootstrap-vue";
import store from "@/store";
import "vue-tree-halower/dist/halower-tree.min.css";
import { VTree, VSelectTree } from "vue-tree-halower";
import whitelabelStoreModule from "../../../pages/whitelabel/whitelabelStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import axios from "@/libs/axios";
import ExportButton from "@/views/pages/export/ExportButton.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
const APP_URL = process.env.VUE_APP_URL;
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";
export default {
  components: {
    ExportButton,
    BTable,
    BCard,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BTableSimple,
    VSelectTree,
    BThead,
    vSelect,
    VTree,
    BTr,
    BTh,
    BForm,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    ToastificationContent,
    BAlert,
    BTfoot,
    BTd,
    BTbody,
    flatPickr,
    HierarchyUsers,
  },
  name: "payments-methods",
  data() {
    return {
      totalPlayers: null,
      dateFrom: moment().format("YYYY-MM-DD:00:00:00"),
      dateTo: moment().format("YYYY-MM-DD:23:59:59"),
      currency: "",
      currenciesOptions: [],
      dataGamesFullDetails: [],
      selectedPaymentMethod: [],
      timeOut: null,
      paymentMethods: [],
      transactionId: "",
      userName: "",
      transactionStatus: "",
      data: {
        principal: [],
        secondLine: "",
      },
      isHistorical: false,
      rangeDate: "",
      idUserSelect: "",
      loadindData: false,
      typeUser: "",
      loadindDataBalancePlayer: false,
      dataTotal: {
        totals: {
          recharges: 0,
          retirement: 0,
          diff: 0,
          agents: 0,
        },
      },
      width: 0,
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },
  watch: {
    dateFrom() {
      this.fetchData();
    },
    dateTo() {
      this.fetchData();
    },
  },
  setup() {
    const WL_APP_STORE_MODULE_NAME = "app-whitelabel";
    store.registerModule(WL_APP_STORE_MODULE_NAME, whitelabelStoreModule);
  },
  computed: {
    statusOptions() {
      return [
        { key: this.$t("status.transaction_pending"), value: "pending" },
        { key: this.$t("status.transaction_success"), value: "completed" },
        { key: this.$t("status.transaction_fail"), value: "failed" },
      ];
    },
    currencySelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.currency;
    },
    whitelabelSelectNavbar() {
      return this.$store.state.whitelabelCurrencyNabvar.whitelabel;
    },
    exportFilter() {
      return {
        id: this.selectedPaymentMethod.productId,
        transactionStatus: this.transactionStatus?.value,
        userName: this.userName,
        transactionId: this.transactionId,
        datein: this.dateFrom,
        dateout: this.dateTo,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar._id,
        isHistorical: this.isHistorical,
      };
    },
  },
  methods: {
    yearDropdownPlugin,
    lazyFetchData() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.timeOut = setTimeout(() => {
        this.fetchData();
      }, 500);
    },
    async fetchData() {
      if (!this.validateData()) return;
      try {
        this.loadingData = true;
        const payload = {
          id: this.selectedPaymentMethod.productId,
          transactionStatus: this.transactionStatus?.value,
          userName: this.userName,
          transactionId: this.transactionId,
          datein: new Date(this.dateFrom).toISOString(),
          dateout: new Date(this.dateTo).toISOString(),
          currency: this.currencySelectNavbar,
          whitelabel: this.whitelabelSelectNavbar._id,
          isHistorical: this.isHistorical,
        };
        const { data } = await axios.get(
          `${APP_URL}/transaction/report-payment-method`,
          { params: payload }
        );
        this.data = data;
      } catch (error) {
        console.log(error);
        axiosErrorHandle(error);
      } finally {
        this.loadingData = false;
      }
    },
    async exportsData() {
      const payload = {
        datein: this.dateFrom,
        dateout: this.dateTo,
        id: this.idUserSelect,
        currency: this.currencySelectNavbar,
        whitelabel: this.whitelabelSelectNavbar._id,
        exportExcel: false,
      };
      const { data } = await store.dispatch(
        "app-client/fetchAgentsByBrandsReport",
        payload
      );
    },
    resetData() {
      this.data = [];
    },
    validateData() {
      if (this.dateTo < this.dateFrom) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "XIcon",
            text: this.$t("review_dates"),
            variant: "danger",
          },
        });
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.width = window.innerWidth;
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
    });

    this.paymentMethods = this.whitelabelSelectNavbar.paymentMethods;

    if (!this.paymentMethods.length) return;
    this.selectedPaymentMethod = this.whitelabelSelectNavbar.paymentMethods[0];
    console.log(this.selectedPaymentMethod);
    this.fetchData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.tree-block {
  float: left;
  width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 300px;
}
.selected {
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  background-color: #efb810;
  border: 1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
</style>
